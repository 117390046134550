import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import CanalLogo from './components/CanalLogo';
import EstadoBadge from './components/EstadoBatch';
import {formatDate,fetchData} from './functions/functions';


function App() {
  const [batches, setBatches] = useState([]);

  useEffect(() => {
    // Función para cargar los datos iniciales y establecer la conexión WebSocket
    const fetchDataAndSetupWebSocket = async () => {
      try {

            // Llamar a fetchData al montar el componente
        const fetchDataCallback = fetchData(setBatches);
        fetchDataCallback();
        // const WS_URL_GET_API_DEV = 'ws://localhost:4000';
        //const WS_URL_GET_API_PROD = 'ws://ventasatc.cl/api/';
        // Establecer conexión WebSocket
        const ws = new WebSocket('wss://atcbatch.onrender.com/');

        ws.onopen = () => {
          console.log('WebSocket connected');
        };

        ws.onmessage = (event) => {
          const message = JSON.parse(event.data);
          console.log('Mensaje recibido del WebSocket:', message);
        
          if (message.type === 'insert') {
            // Agregar el nuevo registro al estado
            setBatches(prevBatches => [...prevBatches, message.newRecord]);
          } else if (message.type === 'update') {
            // Actualizar el registro correspondiente en el estado
            setBatches(prevBatches => prevBatches.map(batch => {
              if (batch.batch === message.updatedRecord.batch) {
                return {
                  ...batch,
                  mueble_ptl: message.updatedRecord.mueble_ptl || batch.mueble_ptl,
                  estado: message.updatedRecord.estado || batch.estado,
                  responsable: message.updatedRecord.responsable || batch.responsable
                };
              }
              return batch;
            }));
          } else if (message.type === 'updateEnd') {
            fetchDataCallback();
          }
        };
        
        ws.onerror = (error) => {
          console.log('WebSocket Error: ', error);
        };

        ws.onclose = () => {
          console.log('WebSocket Disconnected');
        };
        // Cleanup function para cerrar el WebSocket al desmontar el componente
        return () => {
          ws.close();
        };
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Llamar a fetchDataAndSetupWebSocket al montar el componente
    fetchDataAndSetupWebSocket();
  }, []);

  return (
    <div className="container tabla-datos">
      <table className="table table-bordered table-responsive display nowrap table-hover" style={{ overflow: "auto", width: "100%" }}>
        <thead>
          <tr>
            <th>BATCH</th>
            <th>Cant SO</th>
            <th>Mueble PTL</th>
            <th>Canal</th>
            <th>Fecha Creación</th>
            <th>Fecha Entrega</th>
            <th>Estado</th>
            <th>Responsable</th>
          </tr>
        </thead>
        <tbody>
          {batches.map((batch, index) => (
            <tr key={index}>
              <td>
                <span className="badge bg-success">{batch.batch}</span>
              </td>
              <td>
                <span className="badge text-bg-secondary">{batch.cant_so}</span>
              </td>
              <td style={{ fontWeight: "700" }}>{batch.mueble_ptl}</td>
              <td style={{ textAlign: 'center' }}>
                <CanalLogo canal={batch.canal} />
              </td>
              <td style={{ fontWeight: "700" }}><i className="fa-solid fa-calendar-days"></i> {formatDate(batch.fecha_creacion)}</td>
              <td style={{ color: "#AB1F01", fontWeight: "700" }}><i className="fa-solid fa-calendar-days"></i> {formatDate(batch.fecha_entrega)}</td>
              <td>
                <EstadoBadge estado={batch.estado} />
              </td>
              <td style={{ fontWeight: "700" }}>{batch.responsable}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default App;
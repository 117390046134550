// Archivo: CanalLogo.js
import React from 'react';
import falabellaImage from '../img/falabella.png'; // Asegúrate de que las rutas sean correctas
import RipleyImage from '../img/ripley.png';
import MELIImage from '../img/mercadolibre.png';
import RedbullImage from '../img/redbull.png';
import DespensaImage from '../img/despensa.png';


const CanalLogo = ({ canal }) => {
  const logoMap = {
    Falabella: falabellaImage,
    Ripley: RipleyImage,
    MELI: MELIImage,
    Redbull: RedbullImage,
    DC: DespensaImage
  };

  const logoSrc = logoMap[canal];
  const altText = `Logo de ${canal}`;

  return logoSrc ? (
    <img src={logoSrc} alt={altText} style={{ textAlign: 'center' }} /> 
  ) : (
    <span>{canal}</span>
  );
};

export default CanalLogo;

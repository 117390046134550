import axios from 'axios';

export function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
  }
  
  export function fetchData(setBatches) {
    return async () => {
      try {
        //const URL_GET_API_DEV = 'http://localhost:4000/batch';
        const URL_GET_API_PROD = 'https://atcbatch.onrender.com/batch';
        const response = await axios.get(URL_GET_API_PROD);
        setBatches(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  }
  
// Archivo: EstadoBadge.js
import React from 'react';

const EstadoBadge = ({ estado }) => {
  let badgeColor = '';
  let icon = '';

  // Lógica para determinar el color del badge y el icono
  switch (estado) {
    case 'Creado':
      badgeColor = 'bg-primary';
      icon = 'fa-solid fa-database fa-beat'; // Cambia por el icono adecuado
      break;
    case 'En Progreso':
      badgeColor = 'bg-warning';
      icon = 'fa-sync fa-spin'; // Cambia por el icono adecuado
      break;
    case 'Completado':
      badgeColor = 'bg-success';
      icon = 'fa-check-circle'; // Cambia por el icono adecuado
      break;
    case null:
        badgeColor = 'bg-secondary';
        icon = 'fa-users';
        break;
    // Agrega más casos según sea necesario
    default:
      badgeColor = 'bg-secondary';
      icon = 'fa-question-circle'; // Icono por defecto para estados desconocidos
      break;
  }

  return (
    <span className={`badge ${badgeColor}`}>
      <i className={`fa-solid ${icon}`}></i> {estado}
    </span>
  );
};

export default EstadoBadge;
